<template>
    <v-card hover>
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">메뉴 순서 변경</v-col>
            </v-row>
        </v-card-title>

        <v-card-text class="pb-0 pl-7 pr-7 pt-3">
            <v-row>
                <v-col class="pb-0 title black--text">
                    <template v-for="(breadCrumbItem, breadCrumbIdx) in breadCrumbs">
                        <v-icon :key="'bread_crumb_delim_'+breadCrumbIdx" v-if="breadCrumbIdx > 0" small class="ml-2 mr-2">mdi-greater-than</v-icon>
                        <span :key="'bread_crumb'+breadCrumbIdx"> {{ breadCrumbItem }} </span>
                    </template>
                    <br v-if="breadCrumbs.length > 0"/>
                    <span class="body-2 grey--text">메뉴 순서를 드래그하여 변경해 주세요</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0">
                    <!-- <div style="overflow-y:auto; max-height:200px; border:1px solid #eeeeee; ">
                        <v-list>
                            <v-list-item-group multiple>
                                <template  v-for="(menuItem, menuIdx) in neighborMenuList">
                                    <v-divider v-if="menuIdx > 0" :key="'divider_' + menuIdx"></v-divider>
                                    <v-list-item v-bind:key="menuIdx" :value="menuItem.menu_seqno">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-action>
                                                <v-checkbox
                                                :input-value="active"
                                                color="blue accent-4"
                                                ></v-checkbox>
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="menuItem.menu_name"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>   
                                    </v-list-item>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </div>      -->

                    <v-card outlined>
                        <v-simple-table>
                            <template v-slot:default>
                                <draggable v-model="neighborMenuList" tag="tbody" @start="onDragStart" @end="onDragEnd" handle=".handle" style="font-size:18px; color:#333333;">
                                    <tr style="height:68px;" v-for="(menuItem, menuIdx) in neighborMenuList" :key="'menu_' + menuIdx"
                                        :class="{ 'handle': true, 'selectedTr': (menuIdx == selectedIdx) }">
                                        <td class="pl-5 font-text-mid">{{ menuItem.menu_name }}</td>
                                        <td class="font-text-mid text-center">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon v-on="on">
                                                        <v-icon>mdi-drag-horizontal-variant</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>드래그하여 순서를 변경할 수 있습니다</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </draggable>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>

        </v-card-text>
        <v-card-actions class="">
            <v-row>
                <v-col cols="12" class="pr-8 pb-3 pt-3 text-right">
                    <v-btn class="ma-2" width="130px" color="primary" text v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>

</template>

<script>
import svcVoiceScnApi from '@/api/svc_voice_scn'
import {mapGetters} from 'vuex'
import draggable from 'vuedraggable'

export default {
    data: () => ({
        neighborMenuList: [],
        selectedIdx: 0,
    }),
    components: {
        draggable
    },
    computed: {
        ...mapGetters({
            allMenuList: 'svcVoiceScn/getMenuList',
            selectedMenu: 'svcVoiceScn/getSelectedMenu',
        }),
        breadCrumbs() {
            let ret = []

            let selectedMenu = this.selectedMenu
            if (selectedMenu) {
                let menuHist = [selectedMenu]

                for (let i = 0; i < selectedMenu.menu_depth; i++) {
                    let lastMenu = menuHist[menuHist.length - 1]
                    let parentMenu = null
                    if (lastMenu.parent_menu_seqno > 0) {
                        this.allMenuList.forEach(menuItem => {
                            if (menuItem.menu_seqno == lastMenu.parent_menu_seqno) {
                                parentMenu = menuItem
                            }
                        })
                    }
                    if (parentMenu) {
                        menuHist.push(parentMenu)
                    } else {
                        break
                    }
                }

                for (let i = menuHist.length - 1; i > 0; i--) {
                    ret.push(menuHist[i].menu_name)
                }
            }

            return ret
        },
    },
    mounted() {
        this.neighborMenuList.splice(0, this.neighborMenuList.length)

        let allMenuList = this.allMenuList
        let selectedMenu = this.selectedMenu

        allMenuList.forEach(item => {
            if (item.parent_menu_seqno == selectedMenu.parent_menu_seqno) {
                this.neighborMenuList.push(item)
            }
        })
    },
    methods: {
        closePopup() {
            this.$emit('cancel')
        },
        submitPopup() {
            let menuSeqnoList = []
            this.neighborMenuList.forEach(item => {
                menuSeqnoList.push(item.menu_seqno)
            })

            let reqForm = {
                parent_menu_seqno: this.selectedMenu.parent_menu_seqno,
                menu_seqno_list: menuSeqnoList
            }
            this.$store.dispatch('openConfirm', {
                message: '변경된 순서를 저장하시겠습니까?',
                va: this,
                okCb: function (va) {
                    svcVoiceScnApi.changeMenuOrderList(reqForm, () => {
                        va.$store.dispatch('openAlert', {
                            va: va,
                            message: '메뉴 순서가 변경 되었습니다',
                            closeCb: (va) => {
                                va.$emit('submit')
                            }
                        })
                    }, (err) => {
                        va.$store.dispatch('openAlert', {
                            message: '메뉴 순서 변경을 실패 했습니다',
                            sub_message: err,
                        })
                    })
                }
            })
        },
        onDragStart(p) {
            this.selectedIdx = p.oldIndex
        },
        onDragEnd(p) {
            let ctx = this
            setTimeout(() => {
                ctx.selectedIdx = -1
            }, 1000)
        }
    }
}
</script>
